<template>
    <div :class="`flex flex-col ${pt ? pt : 'mt-3'}`">
      <p v-if="!hideLabel" class="label_heading">Units</p>
      <select
        :id="widgetId + '2'"
        class="fields focus:ring-indigo-500 border-0 block left-0 font-color leading-none"
        v-model="selectedUnit"
        @change="unitChangeHandler"
      >
        <option :key="index" v-for="(tag, index) in units?.units">
          {{ tag }}
        </option>
      </select>
    </div>
  </template>
  <script>
  import helperServices from "../../../helper-services.js";
  import { db } from "../../../db";
  export default {
    name: "UnitSelect",
    props: {
      mnemonic: {
        type: String,
        default: "",
      },
      displayId: {
        type: String,
      },
      widgetId: {
        type: String,
      },
      isCustomEvents: {
        type: Boolean,
        default: false,
      },
      selectedVal: {
        type: String,
        default: "",
      },
      pt: {
        type: String,
      },
      category: {
        type: String,
        required: false,
      },
      reverse: {
        type: Boolean,
        required: false,
        default: false,
      },
      hideLabel: {
        type: Boolean,
        required: false,
        default: false,
      },
      standaredUnit: {
        type: String,
        required: false,
      },
      isMetrics: {
        type: Boolean,
        required: false,
        default: false,
      },
      interChange:{
        type:Boolean,
        required:false,
        default:false
      }
    },
    data: () => ({
      units: null,
      selectedUnit: "",
      display_unit: null,
    }),
    async mounted() {
      const wellDet = this.$store.state.disp.displays[this.displayId];
      if (wellDet && wellDet.wellId) {
        if (this.mnemonic != "") {
          //get unit of mnemonics
          let mapping = await helperServices.getIndexDetails(wellDet.wellId);
          mapping = mapping.data.logs[wellDet.logType];
          const tags = mapping.tags;
          const categories = mapping.categories;
          const mnemonicKey = tags.findIndex((t) => t == this.mnemonic);
          const sourceMnemonic = mapping.source[mnemonicKey];
          this.display_unit = null;
          for(let type in mapping.mnemonics){
            if(mapping.mnemonics[type].hasOwnProperty(sourceMnemonic)){
              this.display_unit = mapping.mnemonics[type][sourceMnemonic]?.display_unit
              break;
            }
          }
          if (this.selectedVal && this.selectedVal != "") {
            this.selectedUnit = this.selectedVal;
          } else {
            this.selectedUnit = mapping.units[mnemonicKey];
          }
          let catregory = categories[mnemonicKey];
          if (this.category && this.category != "") {
            catregory = this.category;
          }
          this.units = await helperServices.getUnitCategories(catregory);
          if (this.isMetrics && this.units && this.display_unit == this.selectedVal) {
            this.setDefaultConversionfactor(this.units.units_category_id);
          }
        }
      }
    },
    methods: {
      setDefaultConversionfactor(categoryId) {
        db.unitfactors
          .where("units_category_id")
          .equals(categoryId)
          .each((fact) => {
            if (
              fact.from_unit == this.selectedVal &&
              fact.to_unit == this.standaredUnit
            ) {
              console.log(
                "unit_conversion_factor_change-for metrics well::",
                fact
              );
              if(this.interChange){
                const fromUnit = fact.from_unit;
                const toUnit = fact.to_unit;
                fact.from_unit=toUnit;
                fact.to_unit=fromUnit;
              }
  
              this.$emit("change_unit_handler", fact);
            }
          });
      },
    },
    watch: {
      selectedUnit: {
        handler(newValue, oldValue) {
          if (oldValue != newValue) {
            if (this.units && this.units.units_category_id) {
              console.log("selectedUnit watcheer:", oldValue, newValue);
              const from = this.reverse ? newValue : oldValue;
              const to = this.reverse ? this.standaredUnit : newValue;
              if (this.standaredUnit == newValue) {
                let emptDet = {
                  unit_conversion_factor_id: 7,
                  units_category_id: 1,
                  from_unit:this.standaredUnit ,
                  to_unit: this.standaredUnit,
                  conversion_factor: 1,
                };
                this.$emit("change_unit_handler", emptDet);
              } else {
                db.unitfactors
                  .where("units_category_id")
                  .equals(this.units.units_category_id)
                  .each((fact) => {
                    if (fact.from_unit == from && fact.to_unit == to) {
                      console.log("unit_conversion_factor_change", fact);
                      if (this.isCustomEvents) {
                        if (this.reverse) {
                          fact.to_unit = fact.from_unit;
                          fact.from_unit = fact.to_unit;
                        }
                        this.$emit("change_unit_handler", fact);
                      } else {
                        console.log('elsof uniselect')
                        this.$store.dispatch("rect/changeUnitConversionFactor", {
                          displayId: this.displayId,
                          widgetId: this.widgetId,
                          value: fact.conversion_factor,
                          unit: fact.to_unit,
                        });
                      }
                    }
                  });
              }
            }
          }
        },
      },
    },
  };
  </script>
  <style scoped src="../../../assets/styles/propstyle.css"></style>
  